import React, { Component } from "react";
// import { SITE_LOGO } from '../../utils/helpers';
import { Helmet } from 'react-helmet';

class HomePage extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>MyHealthcare : Patient Home Page</title>
          <meta name="description" content="Patient home page Description" />
        </Helmet>
        <div className="d-flex justify-content-center mt-5">
          {/* {SITE_LOGO} */}
          Weblink Appllication
        </div>
      </>
    );
  }
}

export default HomePage;
