/* eslint-disable */
import React from "react";
import { withRouter } from "react-router-dom";
import "./VonageMpcVideoConferencing.css";
import PropTypes from 'prop-types';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { Drawer, Button, Form, Input, notification } from 'antd';
import {INVITE_PATIENT_RELATIVE, axiosi} from "../../../utils/constant";
import END_CALL from '../../../assets/images/endcall.png';
import MIC_MUTED from '../../../assets/images/mic-muted.png';
import MIC_NON_MUTED from '../../../assets/images/mic.png';
import VIDEO_OFF from '../../../assets/images/video_off.png';
import VIDEO_ON from '../../../assets/images/video_on.png';
import USER_AVATAR from '../../../assets/images/user2.png';

class VonageMpcVideoConferencing extends React.Component {

    state = {
        frameRate: 7,
        resolution: '320x240',
        isMuted: false,
        isVideoDisabled: false,
        showAddParticipantDrawer: false,
        canAddRelative: true,
        isRelativeInviteDisabled: false,
        jdr: 'junior_doctor',
        dr: 'doctor',
        reconnecting: false,
        reconnected: false,
        reconnecting_msg: null
    }

    constructor(props) {
        super(props);
        this.addParticipantFormRef = React.createRef();
    }

    componentDidMount() {
        this.startStreaming();
    }

    startStreaming() {
        const {vcInfo, person_type, totalParticipants, frameRate, resolution} = this.props;

        const apiKey = vcInfo.key;
        const sessionId = vcInfo.session_id;
        const token = vcInfo.token;
        // const apiKey = "46894654";
        // const sessionId = "2_MX40Njg5NDY1NH5-MTY2OTcxNDUyOTMwNH5hSkpLRjJTdkh4eVI5czRhMDRxNGpuWWh-UH4";
        // const token = "T1==cGFydG5lcl9pZD00Njg5NDY1NCZzaWc9ZDc5Nzk4MzA5OWRkMGU4ZGZkOGFkOTRhNTg0NjRlOWUwNDUxMGQ3YzpzZXNzaW9uX2lkPTJfTVg0ME5qZzVORFkxTkg1LU1UWTJPVGN4TkRVeU9UTXdOSDVoU2twTFJqSlRka2g0ZVZJNWN6UmhNRFJ4TkdwdVdXaC1VSDQmY3JlYXRlX3RpbWU9MTY2OTcxNDUyOSZyb2xlPXB1Ymxpc2hlciZub25jZT0xNjY5NzE0NTI5LjQyODExNTEyMjc4MzYzJmV4cGlyZV90aW1lPTE2Njk4MDA5MjkmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=";
        const pname = vcInfo?.patient_name.toLowerCase()
        .split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        + " (" + ((person_type == 'patient_relative') ? 'PR' : 'P') +")";

        const room = new window.VideoExpress.Room({
            apiKey: apiKey, // add your OpenTok API key
            sessionId: sessionId, // add your OpenTok Session ID
            token: token, // add your OpenTok token
            roomContainer: 'vc_multiparty_subscribers',
            participantName: pname,
            managedLayoutOptions: {
                speakerHighlightEnabled: true,
                speakerHighlightColor: '#d24350',
                layoutMode: "active-speaker", // grid, active-speaker
                deviceLayoutMode: "desktop" // desktop, mobile
            }
        });

        this.room = room;
        const $this = this;
        room.join({
            publisherProperties: {
                resolution: resolution,
                frameRate: frameRate,
                mirror: false,
                // audioBitrate: 15,
                // audioFallbackEnabled: true,
            }
        });

        if (person_type == 'patient_relative') {
            // console.log('>>>> salman room', room, room.participants);
            // console.log(Object.values(room.participants).length);

            room.on('connected', () => {
                // console.log('Connected');
                // console.log('Connected', Object.values(room.participants).length, room.participants);
                // console.log('>>> Connected KK', {
                //     ...this.room.participants
                // });

                setTimeout(() => {
                    const allParticipants = {...this.room.participants};
                    for (const [vonageParticipantId, participant] of Object.entries(allParticipants)) {
                        if (typeof participant.isMe != 'undefined' && participant.isMe) {
                            continue;
                        }
                        if (typeof participant.isMe != 'undefined' && !participant.isMe && 
                            typeof participant.name != 'undefined' && participant.name.indexOf('(PR)') !== -1) {
                            $this.endCall();
                            $this.relativeExistsInCall();
                        }
                    }
                }, 1000);
            });
            // room.on('connected', (participant, reason) => {
            // console.log('participant left: ', participant?.name);
            // console.log('reason: ', reason);
            // });

            // room.on('participantJoined', (participant) => {
            //     console.log('participant joined: ', participant.name);
            // });
            // room.on('participantLeft', (participant, reason) => {
            //     console.log('participant left: ', participant.name);
            //     console.log('reason: ', reason);
            // });
            
            // for (const [vonageParticipantId, participant] of Object.entries(room.participants)) {
            //     console.log(`${key}: ${value}`);
            // }
        }

        room.on('connected', (participant, reason) => {
            room.camera.setDisabledImageURI(USER_AVATAR);

            setTimeout(() => {
                $this.checkForTotalParticipants(totalParticipants);
            }, 1000);
        });

        room.on('participantJoined', (participant) => {
            // console.log('>> Salman participantJoined', participant);
            participant.on('cameraCreated', (cameraSubscriber) => {
                cameraSubscriber.setDisabledImageURI(USER_AVATAR);
            });
        });

        room.on('participantLeft', (participant, reason) => {
            $this.checkInCallParticipants();
        });

        room.on("reconnecting", () => {
            $this.reconnecting();
        });
        room.on("reconnected", () => {
            $this.reconnected();
            setTimeout(() => $this.rejoined(), 1500);
        });
        room.on('disconnected', () => {
            notification.destroy();
            notification.info({
                message: `Call Disconnected`,
                description: "Call has been disconnected!"
            });
            $this.rejoined();
            $this.endCall();
        });
    }

    reconnecting() {
        this.setState({
            reconnecting: true,
            reconnected: false,
            reconnecting_msg: 'Reconnecting...'
        });
    }

    reconnected() {
        this.setState({
            reconnecting: false,
            reconnected: true,
            reconnecting_msg: 'Reconnected!'
        });
    }

    rejoined() {
        this.setState({
            reconnecting: false,
            reconnected: false,
            reconnecting_msg: null
        })
    }

    checkInCallParticipants() {
        const {jdr, dr} = this.state;
        const allParticipants = {...this.room.participants};
        let doctorsAvailable = [];
        console.log('>> salman allParticipants', allParticipants)

        for (const [vonageParticipantId, participant] of Object.entries(allParticipants)) {
            if (typeof participant.isMe != 'undefined' && participant.isMe) {
                continue;
            }
            let participantType = null;
            if (participant.connectionData != undefined && participant.connectionData != null) {
                const connectionData = JSON.parse(participant.connectionData);
                participantType = connectionData.type;
            }
            // if (typeof participant.name != 'undefined' && participant.name.indexOf(jdr) !== -1) {
            if (participantType == jdr) {
                doctorsAvailable.push(jdr);
            }
            // if (typeof participant.name != 'undefined' && participant.name.indexOf(dr) !== -1) {
            if (participantType == dr) {
                doctorsAvailable.push(dr);
            }
        }

        if (doctorsAvailable.length == 0) {
            notification.info({
                message: `Call Disconnected`,
                description: "Call has been disconnected as there is no doctor available in the video call!",
                placement: 'bottomRight'
            });
            this.endCall();
        }
    }

    checkForTotalParticipants(maxParticipants) {
        const allParticipants = {...this.room.participants};
        if (Object.values(allParticipants).length > maxParticipants) {
            notification.info({
                message: `Call Disconnected`,
                description: "Maximum number of users have already joined this call!"
            });
            this.endCall();
        }
    }

    relativeExistsInCall() {
        this.props.relativeExistsInCall();
    }

    endCall() {
        this.room.leave();
        this.setState({
            isMuted: false,
            isVideoDisabled: false
        });
        this.props.callEnded();
        const vc_multiparty_subscribers = document.getElementById('vc_multiparty_subscribers');
        if (vc_multiparty_subscribers != null) {
            vc_multiparty_subscribers.innerHTML = '';
        }
    }

    toggleMicrophone() {
        if (typeof this.room == 'undefined') {
            return;
        }
        const {isMuted} = this.state;
        if (isMuted) {
            this.room.camera.enableAudio();
        } else {
            this.room.camera.disableAudio();
        }
        this.setState({isMuted: !isMuted});
    }

    toggleSelfVideo() {
        if (typeof this.room == 'undefined') {
            return;
        }
        const {isVideoDisabled} = this.state;
        if (isVideoDisabled) {
            this.room.camera.enableVideo();
        } else {
            this.room.camera.disableVideo();
        }
        this.setState({isVideoDisabled: !isVideoDisabled});
    }

    async addParticipant(formValues) {
        const {vcInfo} = this.props;
        console.log(vcInfo.booking_id)
        this.setState({isRelativeInviteDisabled: true});

        try {
            const response = await axiosi.post(INVITE_PATIENT_RELATIVE, {
                booking_id: vcInfo.booking_id,
                // booking_id: 102648, // :Fix
                mobile_no: formValues.mobile_no
            });
            if (typeof response.data != 'undefined' && typeof response.data.status != 'undefined' && response.data.status == 200) {
                notification.success({
                    message: `Invite Relative`,
                    description: "Invitation link to join the tele-consult has been sent via SMS to " + formValues.mobile_no + "!",
                    placement: 'bottomRight'
                });
                this.addParticipantFormRef.current.setFieldsValue({
                    mobile_no: null
                });
                this.setState({
                    showAddParticipantDrawer: false,
                    isRelativeInviteDisabled: false
                });
            } else {
                this.setState({isRelativeInviteDisabled: false});
                notification.error({
                    message: `Invite Relative`,
                    description: "Not able to send invite, try again!",
                    placement: 'bottomRight'
                });
            }
        } catch (error) {
            this.setState({isRelativeInviteDisabled: false});
            notification.error({
                message: `Invite Relative`,
                description: "Request failed, not able to send invite, try again!",
                placement: 'bottomRight'
            });
        }
    }

    render() {
        const {reconnecting, reconnected, reconnecting_msg, isVideoDisabled, isMuted, showAddParticipantDrawer, canAddRelative, isRelativeInviteDisabled} = this.state;
        const {person_type, can_invite_relative} = this.props;

        return (
            <React.Fragment>
                <div className="multi_party_calling">
                    <div className="multi_party_calling_body">
                        <div id="vc_multiparty_subscribers"></div>
                    </div>
                    <div className="multi_party_calling_footer text-center">
                        <button onClick={() => this.toggleSelfVideo()} type="button" className={"active control-btn"} title={isVideoDisabled ? "Enable Video" : "Disable Video"}>
                            <img src={isVideoDisabled ? VIDEO_OFF : VIDEO_ON} alt="mic-icon" />
                        </button>
                        <button onClick={() => this.toggleMicrophone()} type="button" className={"active control-btn"} title={isMuted ? "Unmute Microphone" : "Mute Microphone"}>
                            <img src={isMuted ? MIC_MUTED : MIC_NON_MUTED} alt="mic-icon" />
                        </button>
                        <button onClick={() => this.endCall({ notify: true })} type="button" className="control-btn" title="End Call">
                            <img src={END_CALL} alt="icon" />
                        </button>
                        {(person_type && person_type == 'patient' && can_invite_relative) ? <button onClick={() => this.setState({showAddParticipantDrawer: true})} type="button" className="control-btn addparticipant-btn" title="Add Participant">
                            <UsergroupAddOutlined />
                        </button> : null}

                        {reconnecting || reconnected ? <div className={reconnecting ? "alert alert-danger container" : "alert alert-success container"}>{reconnecting_msg}</div> : null}
                    </div>
                </div>
                <Drawer
                    title="Add Participant"
                    placement="bottom"
                    closable={true}
                    visible={showAddParticipantDrawer}
                    key="bottom"
                    onClose={() => this.setState({showAddParticipantDrawer: false})}
                >
                    <p>Please enter mobile number of person, whom you want to join this call</p>
                    <Form
                        ref={this.addParticipantFormRef}
                        name="add_participant_form"
                        onFinish={(e) => this.addParticipant(e)}
                        initialValues={{
                            mobile_no: null
                        }}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="mobile_no"
                            label={null}
                            rules={[
                                { required: true, message: 'Mobile number is required!' },
                                {
                                    pattern: /^\d+$/,
                                    message: "Not a valid number!",
                                }
                            ]}
                        >
                            <Input maxLength={10} placeholder="Mobile Number" />
                        </Form.Item>
                        <Form.Item style={{textAlign: 'right'}}>
                            <Button disabled={isRelativeInviteDisabled} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </React.Fragment>
        );
    }
}

VonageMpcVideoConferencing.propTypes = {
    person_type: PropTypes.any,
    vcInfo: PropTypes.any,
    booking_id: PropTypes.any,
    can_invite_relative: PropTypes.any,
    patient: PropTypes.any,
    callEnded: PropTypes.any,
    relativeExistsInCall: PropTypes.any
};

export default withRouter(VonageMpcVideoConferencing);