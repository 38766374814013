import React, { Component } from "react";
import "./join-call.css";
import InvalidConsultation from "../../components/consultation/invalid";
import RelativeConsultation from "../../components/consultation/relative-consultation";
import ConsultLoader from "../../components/loaders/spinner";
import {
  axiosi
} from "../../utils/constant";
import PropTypes from 'prop-types';

class RelativeJoinCallPage extends Component {
  state = {
    component: null,
    loading: true,
    appointment: {}
  };

  static get propTypes() { 
    return {
      match: PropTypes.shape({
        params: PropTypes.shape({
          code: PropTypes.string.isRequired
        })
      })
    };
  }

  async getBooking() {
    let { code } = this.state;
    axiosi
      .get("patients/booking/" + code)
      .then((appointment) => {
        this.setState({
          loading: false,
          component: "consultation",
          appointment: appointment.data.result
        });
      })
      .catch(() => {
        if (window.navigator.onLine) {
          this.setState({
            loading: false,
            component: "expired",
          });
        }
      });
  }

  componentDidMount() {
    try {
      this.setState({
        code: this.props.match.params.code
      }, () => this.getBooking());
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { component, loading, appointment } = this.state;
    return (
      <>
        {loading ? <ConsultLoader /> : null}
        {component === "expired" ? <InvalidConsultation /> : null}
        {component === "consultation" && appointment.booking_code !== undefined ? (
          <RelativeConsultation appointment={appointment} />
        ) : null}
      </>
    );
  }
}

export default RelativeJoinCallPage;
