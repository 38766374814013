import React, { Component } from "react";
import "./spinner.css";
import { LOADER } from '../../utils/helpers';

class ConsultLoader extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              {/* {SITE_LOGO} */}
            </div>
          </div>
          <div className="col-12 mt-5">
            <div className="text-center">
              {LOADER}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsultLoader;
