import React, { Component } from "react";
// import { APP_ICONS } from '../../utils/helpers';

class InvalidConsultation extends Component {
  render() {
    return (
      <div className="full_screen">
        <div className="container">

          <div className="row">
            <div className="col-12 text-center">
              <figure className="logo">
                {/* {SITE_LOGO} */}
              </figure>
            </div>

            <div className="cta_wrap gray_gradient no_longer">
              <div className="row">
                <div className="col-12 my-3 text-center">
                  <h5>This link is no longer valid</h5>
                  {/* <p>Please download the Mobile app to book appointments, access your prescriptions and avail other services.</p> */}
                </div>
                {/* {APP_ICONS} */}
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default InvalidConsultation;
