import React, { Component } from "react";
import PropTypes from 'prop-types';
import Moment from "react-moment";
import {
    SITE_LOGO
} from "../../utils/helpers";
import { Button, Form, Input, notification } from 'antd';
import {JOIN_PATIENT_RELATIVE, axiosi, VC_DISCONNECTED} from '../../utils/constant';
import HundredmsVc from '../../components/video-conferencing/hundredms/HundredmsVc'
import VonageMpcVideoConferencing from "../../components/video-conferencing/mpc/VonageMpcVideoConferencing";
import InvalidConsultation from "../../components/consultation/invalid";

class RelativeConsultation extends Component {

    static get propTypes() {
        return {
            appointment: PropTypes.any
        };
    }

    state = {
        isJoinFormDisabled: false,
        vcInfo: {},
        invalidMesage: null,
        isRelativeExistsInCall: false,
        totalParticipants: 2
    }

    constructor(props) {
        super(props);
        this.participantInfoFormRef = React.createRef();
    }

    componentDidMount() {

    }

    async addParticipant(values) {
        const {appointment} = this.props;
        notification.destroy();

        let buff = Buffer.from(appointment.patient_relative_mobile_no, "base64").toString();
        let mobileNo = JSON.parse(buff);
        if (mobileNo != values.mobile_no) {
            this.setState({
                invalidMesage: "Entered mobile number does not match the number invited by the patient."
            });
            return;
        }

        this.setState({isRelativeJoinDisabled: true, invalidMesage: null});
        try {
            const response = await axiosi.post(JOIN_PATIENT_RELATIVE, {
                booking_id: appointment.booking_id,
                // booking_id: 102678, // :Fix
            });
            if (typeof response.data != 'undefined' && response.data.code != 'undefined' && response.data.code == 'EMPTY_OT_SESSION_ID') {
                this.setState({isRelativeJoinDisabled: false});
                notification.error({
                    message: `Join Call`,
                    description: "Video call has not yet started!",
                    placement: 'bottomRight'
                });
            } else if (typeof response.data != 'undefined' && response.data.session_id != 'undefined') {
                this.participantInfoFormRef.current.setFieldsValue({
                    name: null,
                    mobile_no: null
                });
                this.setState({
                    isRelativeJoinDisabled: false,
                    vcInfo: {
                        key: response.data.key,
                        token: response.data.token,
                        session_id: response.data.session_id,
                        patient_name: values.name,
                        booking_id: appointment.booking_id,
                        number_of_participants: appointment.number_of_participants,
                        doctor_room_code: response?.data?.room_code ? response?.data?.room_code : "",
                        call_vendor: response?.data?.call_vendor ? response?.data?.call_vendor : ""
                    }
                });
            } else {
                this.setState({isRelativeJoinDisabled: false});
                notification.error({
                    message: `Join Call`,
                    description: "Not able to join call, try again!",
                    placement: 'bottomRight'
                });
            }
        } catch (error) {
            this.setState({isRelativeJoinDisabled: false});
            notification.error({
                message: `Join Call`,
                description: "Request failed, not able to join call, try again!",
                placement: 'bottomRight'
            });
        }
    }

    callEnded() {
        this.setState({ vcInfo: {} });
        this.callStatus(VC_DISCONNECTED);
    }

    hundrdMsCallEnded() {
        this.setState({ vcInfo: {} });
    }

    callStatus(status) {
        const { appointment } = this.props;
        axiosi.get("vonage/bookings/" + appointment.booking_id + "/status/" + status);
        // .then((response) => {})
        // .catch((error) => {});
    }

    relativeExistsInCall() {
        this.setState({
            isRelativeExistsInCall: true
        });
    }

    render() {
        const { appointment } = this.props;
        const { isRelativeJoinDisabled, vcInfo, invalidMesage, isRelativeExistsInCall } = this.state;
        return (
            <div className="container">

                {(appointment?.patient_relative_mobile_no == null) ? <React.Fragment>
                    <InvalidConsultation />
                </React.Fragment> : null}

                {appointment?.booking_id && appointment?.patient_relative_mobile_no != null ? <React.Fragment>
                    <div className="row">

                        <div className="col align-self-center">
                            <p>
                                Appointment For: <strong>{appointment.patient_name}!</strong>
                            </p>
                        </div>
                        <div className="col-auto pl-0">
                            <figure className="logo">{SITE_LOGO}</figure>
                        </div>
                        <div className="col-12 my-3">
                            <div className="card gray_gradient">
                                <div className="row">
                                    <div className="col">
                                        <p>
                                            <strong>{appointment.doctor_name}</strong>
                                        </p>
                                        <p>
                                            {appointment.speciality_name != null
                                                ? appointment.speciality_name.join(", ") + ", "
                                                : null}
                                            {appointment.hopital_name
                                                ? appointment.hopital_name
                                                : null}
                                        </p>
                                    </div>
                                    <div className="col-auto">
                                        <div className="time_card">
                                            <Moment format="ddd" parse="YYYY-MM-DD HH:mm">
                                                {appointment.booking_date_time}
                                            </Moment>{" "}
                                            <br />
                                            <Moment format="D MMM" parse="YYYY-MM-DD HH:mm">
                                                {appointment.booking_date_time}
                                            </Moment>{" "}
                                            <br />
                                            <span>
                                                <Moment format="h:mm A" parse="YYYY-MM-DD HH:mm">
                                                    {appointment.booking_date_time}
                                                </Moment>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!isRelativeExistsInCall ? <div className="col-12">
                            <p>Please fill in the below form to join the video call.</p>
                            <Form
                                ref={this.participantInfoFormRef}
                                name="add_participant_form"
                                onFinish={(e) => this.addParticipant(e)}
                                initialValues={{
                                    name: null,
                                    mobile_no: null
                                }}
                                labelCol={{ span: 4 }}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    // label={null}
                                    rules={[
                                        { required: true, message: 'Name is required!' }
                                    ]}
                                >
                                    <Input maxLength={100} placeholder="Name" />
                                </Form.Item>
                                <Form.Item
                                    name="mobile_no"
                                    label="Mobile No."
                                    rules={[
                                        { required: true, message: 'Mobile number is required!' },
                                        {
                                            pattern: /^\d+$/,
                                            message: "Not a valid number!",
                                        }
                                    ]}
                                >
                                    <Input maxLength={10} placeholder="Mobile Number" />
                                </Form.Item>
                                {(invalidMesage != null) ? <p className="alert alert-danger">{invalidMesage}</p> : null}
                                <Form.Item style={{textAlign: 'right'}}>
                                    <Button disabled={isRelativeJoinDisabled} type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div> : null}

                        {isRelativeExistsInCall ? <div className="col-12">
                            <div className="alert alert-danger">Maximum number of users have already joined this call.</div>
                        </div> : null}

                    </div>
                </React.Fragment> : null}

                {appointment.booking_id && vcInfo.token && typeof vcInfo?.call_vendor != 'undefined' && vcInfo?.call_vendor != 3 ?
                    <VonageMpcVideoConferencing
                        person_type="patient_relative"
                        vcInfo={vcInfo}
                        patient={null}
                        booking_id={appointment.booking_id}
                        totalParticipants={vcInfo.number_of_participants}
                        can_invite_relative={false}
                        callEnded={() => this.callEnded()}
                        relativeExistsInCall={() => this.relativeExistsInCall()}
                    />
                    : null}

                {appointment.booking_id && vcInfo.doctor_room_code && vcInfo?.call_vendor == 3 ?
                    <HundredmsVc
                        person_type="patient"
                        vcInfo={vcInfo}
                        patient={null}
                        booking_id={appointment.booking_id}
                        can_invite_relative={false}
                        callEnded={() => this.hundrdMsCallEnded()}
                    />
                    : null}

            </div>
        );
    }
}

export default RelativeConsultation;