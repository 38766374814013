/* eslint-disable */
import React from "react";
import { withRouter } from "react-router-dom";
import "./HundredmsVc.css";
import { HUNDRED_MSCALL, INVITE_PATIENT_RELATIVE, axiosi, HUNDRED_MS_P2P_CALL, HUNDRED_MS_MPC_CALL } from "../../../utils/constant";
import { UsergroupAddOutlined } from '@ant-design/icons';
import { Drawer, Button, Form, Input, notification } from 'antd';

class HundredmsVc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vc_call_code: "",
            showAddParticipantDrawer: false,
            isRelativeInviteDisabled: false,
        }
        this.addParticipantFormRef = React.createRef();
    }

    componentDidMount() {
    }

    // cancelVccall() {
    //     this.setState({
    //         vc_call_code: "11234564567854567%*$(##($#)@#($%$(#)@(#$%*$#(@#$*%$(#@#*$#(35676545678765"
    //     }, () => {
    //         this.setState({
    //             vc_call_code: ""
    //         }, () => {
    //             this.props.callEnded();
    //         })
    //     })
    // }

    async addParticipant(formValues) {
        const { vcInfo } = this.props;
        console.log(vcInfo.booking_id)
        this.setState({ isRelativeInviteDisabled: true });

        try {
            const response = await axiosi.post(INVITE_PATIENT_RELATIVE, {
                booking_id: vcInfo.booking_id,
                // booking_id: 102648, // :Fix
                mobile_no: formValues.mobile_no
            });
            if (typeof response.data != 'undefined' && typeof response.data.status != 'undefined' && response.data.status == 200) {
                notification.success({
                    message: `Invite Relative`,
                    description: "Invitation link to join the tele-consult has been sent via SMS to " + formValues.mobile_no + "!",
                    placement: 'bottomRight'
                });
                this.addParticipantFormRef.current.setFieldsValue({
                    mobile_no: null
                });
                this.setState({
                    showAddParticipantDrawer: false,
                    isRelativeInviteDisabled: false
                });
            } else {
                this.setState({ isRelativeInviteDisabled: false });
                notification.error({
                    message: `Invite Relative`,
                    description: "Not able to send invite, try again!",
                    placement: 'bottomRight'
                });
            }
        } catch (error) {
            this.setState({ isRelativeInviteDisabled: false });
            notification.error({
                message: `Invite Relative`,
                description: "Request failed, not able to send invite, try again!",
                placement: 'bottomRight'
            });
        }
    }

    render() {
        const { showAddParticipantDrawer, isRelativeInviteDisabled } = this.state;
        const { person_type, can_invite_relative } = this.props;
        return (
            <React.Fragment>
                {this.props.vcInfo.doctor_room_code ? <>
                    <div className="hundredms-overlay">
                        {/* <div className='hundredms-header' style={{ padding: "8px 15px" }}> */}
                            {/* VC Conferencing */}
                            {/* <button className='btn btn-primary btn-sm pull-right'
                                onClick={() => this.cancelVccall()}
                            >Close</button> */}
                            {/* {(person_type && person_type == 'patient' && can_invite_relative) ? <button style={{ float: "right" }} onClick={() => this.setState({ showAddParticipantDrawer: true })} type="button" className="control-btn addparticipant-btn pull-right" title="Add Participant">
                                <UsergroupAddOutlined />
                            </button> : null} */}
                        {/* </div> */}
                        <div className='hundredms-body'>
                            <iframe
                                title="100ms-app"
                                allow="camera *;microphone *;display-capture *"
                                // src={`${HUNDRED_MSCALL}${this.props.vcInfo.doctor_room_code}`}
                                src={can_invite_relative ? `${HUNDRED_MS_MPC_CALL}${this.props.vcInfo.doctor_room_code}` : `${HUNDRED_MS_P2P_CALL}${this.props.vcInfo.doctor_room_code}`}
                            >
                            </iframe>
                        </div>
                    </div>

                    <Drawer
                        title="Add Participant"
                        placement="bottom"
                        closable={true}
                        visible={showAddParticipantDrawer}
                        key="bottom"
                        onClose={() => this.setState({ showAddParticipantDrawer: false })}
                    >
                        <p>Please enter mobile number of person, whom you want to join this call</p>
                        <Form
                            ref={this.addParticipantFormRef}
                            name="add_participant_form"
                            onFinish={(e) => this.addParticipant(e)}
                            initialValues={{
                                mobile_no: null
                            }}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="mobile_no"
                                label={null}
                                rules={[
                                    { required: true, message: 'Mobile number is required!' },
                                    {
                                        pattern: /^\d+$/,
                                        message: "Not a valid number!",
                                    }
                                ]}
                            >
                                <Input maxLength={10} placeholder="Mobile Number" />
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'right' }}>
                                <Button disabled={isRelativeInviteDisabled} type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Drawer>

                </> : null}
            </React.Fragment>
        );
    }
}

export default withRouter(HundredmsVc);