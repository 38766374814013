import React, { Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

// import PrivateRoute from "../utils/PrivateRoutes";
import HomePage from "../pages/home/home";
import JoinCallPage from "../pages/join-call/join-call";
import VcSounds from '../components/video-conferencing/vc-sounds';
import IncomingCall from '../components/video-conferencing/IncomingCall';
import RelativeJoinCallPage from "../pages/join-call/relative-join-call";

import { APP_SUB_FOLDER } from "../utils/constant";

function App() {
  // props
  return (
    <>
      <Suspense fallback={null}>
        <Switch>
          <Route exact path={`${APP_SUB_FOLDER}/`} component={HomePage} />
          <Route exact path={`${APP_SUB_FOLDER}/join-call/:code`} component={JoinCallPage} />
          <Route exact path={`${APP_SUB_FOLDER}/relative-join-call/:code`} component={RelativeJoinCallPage} />
        </Switch>
      </Suspense>
      <VcSounds />
      <IncomingCall />
    </>
  );
}

export default withRouter(App);
